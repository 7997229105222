

body {
    background-color: #f0f2f5;
  }
  
  .navbar-custom{

    background: linear-gradient(135deg, rgba(65, 35, 153, 1) 0%, rgba(153, 25, 132, 1) 100%);
  }
  
  .card-center {
    margin: auto;
    max-width: 400px;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .logo {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .logo img {
    width: 100px;
  }
  
  
  .sidebar {
    background-color: #5A2092;
    min-height: 100vh;
    padding-top: 100px;
    position: fixed;
    width: 220px;
    top:0;
  }
  
  .sidebar a {
    color: white;
    padding: 15px;
    display: block;
    text-decoration: none;
  }
  
  .sidebar a:hover {
    background-color: #921985;
  }
  
  .content {
    padding: 20px;
  }
  
  @media (max-width: 992px) {
    .sidebar {
      display: none;
    }
  
    .content {
      margin-left: 0;
    }
  }
  
  @media (min-width: 992px) {
    .sidebar {
      display: block;
    }
  
    .content {
      margin-left: 220px;
    }
  }
  

  .modal-90w {
    max-width: 90% !important;
  }
  
  .modal-90w .modal-dialog {
    width: 100%;
  }
  
  .modal-90w .modal-content {
    height: 90vh;
  }
  
  .table-wrapper {
    height: 70vh;
    overflow-y: auto;
  }
  
  .sticky-header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
  

  .logo-image-panel img {

    width: 8rem;
	height: 2rem;
}

.nav-link.active {
  background-color: #0d6efd; /* Adjust the background color as needed */
  color: #fff; /* Adjust the text color as needed */
}

.admin-sidebar a:hover{
  background-color: aliceblue;
  color:black !important;
}

.admin-sidebar .nav-link.active {
  background-color: aliceblue;
  color: black !important;
}